import { func } from '../../utils';
import { SET_SET_SETTINGS } from '../_types';

const initialState = {
    settings: func.getStorageJson('settings') || {},
    countries: func.getStorageJson('countries') || [],

    navigation: [
        { code: 'dash', name: 'Dashboard', icon: 'dripicons-home', link: '', subs: [], },
        {
            code: 'msg', name: 'Messages', icon: 'dripicons-conversation', link: 'messages', subs: [
                { code: 'msg_snt', name: 'Sent messages', link: 'messages/sent', },
                { code: 'msg_sch', name: 'Scheduled messages', link: 'messages/schedules', },
                { code: 'msg_tmp', name: 'Templates', link: 'messages/templates', },
            ],
        },
        {
            code: 'snd', name: 'Sender IDs', icon: 'dripicons-link', link: 'senders', subs: [
                { code: 'snd_pnd', name: 'Pending senders', link: 'senders/pending', },
                {
                    code: 'snd_mng', name: 'Manage senders', link: 'senders/list', rules: [
                        { code: 'msg_add', name: 'Add new' },
                        { code: 'msg_apr', name: 'Approve' },
                        { code: 'msg_rej', name: 'Reject' },
                        { code: 'msg_upd', name: 'Edit' },
                        { code: 'msg_del', name: 'Delete' },
                    ]
                },
            ],
        },
        {
            code: 'usr', name: 'Users', icon: 'dripicons-user-group', link: 'users', subs: [
                {
                    code: 'usr_nrm', name: 'Normal users', link: 'users/list', rules: [
                        { code: 'usr_crd', name: 'Credit user' },
                        { code: 'usr_sus', name: 'Suspend' },
                        { code: 'usr_act', name: 'Activate' },
                    ],
                },
                { code: 'usr_adm', name: 'Admin users', link: 'users/admin', },
                {
                    code: 'usr_acc', name: 'Manage access levels', link: 'users/access', rules: [
                        { code: 'usr_acc_add', name: 'Add access' },
                        { code: 'usr_acc_upd', name: 'Edit access' },
                        { code: 'usr_acc_del', name: 'Delete access' },
                    ],
                },
                { code: 'usr_dev', name: 'Developers', link: 'users/developers', },
                { code: 'usr_wlb', name: 'White Labels', link: 'users/whitelabels', },
            ],
        },
        // {
        //     code: 'mrk', name: 'Marketing', icon: 'fa fa-diamond', link: 'marketing', subs: [
        //         { code: 'mrk_usr', name: 'Users', link: 'marketing/users', },
        //         { code: 'mrk_clt', name: 'Clients', link: 'marketing/clients', },
        //         { code: 'mrk_com', name: 'Commissions', link: 'marketing/commissions', },
        //         { code: 'mrk_ern', name: 'Earnings', link: 'marketing/earnings', },
        //     ],
        // },
        {
            code: 'rep', name: 'Reports', icon: 'dripicons-graph-pie', link: 'reports', subs: [
                // { code: 'rep_log', name: 'Events log', link: 'reports/eventslog', },
                { code: 'rep_msg', name: 'Messages report', link: 'reports/messages', },
                { code: 'rep_sum', name: 'Messages summary', link: 'reports/messages-summary', },
                { code: 'rep_pay', name: 'Payments report', link: 'reports/payments', },
                { code: 'ref_pay', name: 'Referrals report', link: 'reports/referrals', },
            ],
        },
        { code: 'set', name: 'Settings', icon: 'dripicons-gear', link: 'settings', subs: [], },
    ],

    contactFields: [
        { name: 'Phone number', code: 'NUMBER', },
        { name: 'First name', code: 'FIRST_NAME', },
        { name: 'Last name', code: 'LAST_NAME', },
        { name: 'Custom 1', code: 'CUSTOM1', },
        { name: 'Custom 2', code: 'CUSTOM2', },
        { name: 'Custom 3', code: 'CUSTOM3', },
        { name: 'Custom 4', code: 'CUSTOM4', },
    ],
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_SET_SETTINGS:
            return {
                ...state,
                [action.key]: action.value,
            };
    }
};


export default dataReducer;