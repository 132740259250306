import { AutoComplete } from 'antd';
import React, { useState } from 'react';
import { axius } from '../utils';

const AutoSenders = (props) => {

    const [value, setValue] = useState(props.value);
    const [options, setOptions] = useState([]);
    const [spinning, setSpinning] = useState('');

    return (
        <React.Fragment>
            <AutoComplete
                style={{ width: '100%' }}
                options={options}
                value={props.setValue ? props.value : value}
                onChange={e => {
                    setValue(e);
                    props.setValue(e);
                }}
                onSearch={e => {
                    setOptions([]);
                    if (e) {
                        setSpinning('spinning');
                        axius.get('senders', { name: e, limit: 6 }).then(res => {
                            setSpinning('');
                            if (res.status === 200) {
                                setOptions(res.data.map(row => {
                                    return { label: `${row.name} (${row.user.name})`, value: row.name };
                                }));
                            }
                        });
                    }else{
                        setValue(undefined);
                        props.setValue(undefined);
                    }
                }}
                onSelect={e => {
                    setValue(options.find(opt => opt.value === e).label);
                    props.setValue(options.find(opt => opt.value === e).label);
                    props.onSelect(e);
                }}
                className={spinning}
                placeholder={props.placeholder || 'Search senders...'}
            />
        </React.Fragment>
    );
}

export default AutoSenders;