import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';

export const api = {
    space: 'on',

    key_of: 'fXnIXhcVEbEt8PbXKy2dTcGcwJfIammBMMcwUPXEppJDxLIFqCj9lUTzGgslKwaD',
    key_ip: 'fXnIXhcVEbEt8PbXKy2dTcGcwJfIammBMMcwUPXEppJDxLIFqCj9lUTzGgslKwaD',
    key_qa: 'JsnbvMFLC8Hnm4ZhFxQIAuB26yVVtptU6N1a5kEzlHjJMBBcZjJpzn2RJp5qD4Hd',
    key_on: 'SXn2Jufl2xRCkcTuAKAf9yr893tmSuHP3LMnA1dCgkjdPhCsWCly9IGpFQGKt4lL',

    server_of: 'http://localhost/fayasms/api/',
    server_ip: 'http://356c68e9c7f2.ngrok.io/fayasms/api/',
    server_on: 'https://api.fayasms.com/v3/',
    server_qa: 'https://qa-api.fayasms.com/',

    platform_of: 'localhost',
    platform_qa: 'qa-admin',
    platform_on: 'admin',
}

export const app = {
    version: require('../../package.json').version,
    dbpref: 'faya_',
}

export const initialize = () => {
    if (window.location.host.match(/localhost/i)) {
        api.space = 'of';
    } else if (window.location.host === 'qa-admin.fayasms.com') {
        api.space = 'qa';
    } else {
        api.space = 'on';
    }
    api.rave = api[`rave_${api.space}`];
    api.apiURL = api[`server_${api.space}`];
    api.apiKey = api[`key_${api.space}`];
    api.apiPlatform = api[`platform_${api.space}`];
    api.apiToken = getStorage('token');
}

export const dates = {
    yr: moment().format('YYYY')
}


// Storage
export const setStorage = (key, value) => {
    if (key && value) {
        localStorage.setItem(app.dbpref + key, value);
    }
}
export const getStorage = (key) => {
    const value = localStorage.getItem(app.dbpref + key);
    return value || '';
}
export const setStorageJson = (key, value) => {
    if (key && value) {
        localStorage.setItem(app.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key) => {
    if (key) {
        const value = localStorage.getItem(app.dbpref + key);
        return JSON.parse(value) || [];
    }
}
export const delStorage = (key) => {
    if (key) {
        localStorage.removeItem(app.dbpref + key);
    }
}


export const inArray = (needle, haystack) => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
    }
    return false;
}
export const mergeObj = (obj, src) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}


// Data Request
initialize();

// Spinners
export const fspinner = <div style={{ textAlign: 'center', color: '#999', lineHeight: 320 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-5x"></i></div>;
export const fspinner_sm = <div style={{ textAlign: 'center', color: '#999', lineHeight: 120 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-3x"></i></div>;
export const fspinner_xs = <i className="fa fa-spin fa-circle-o-notch"></i>;

export const redirect = (to) => {
    window.location = to;
}

export const generateOptions = (length, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const hasR = (role) => {
    return true;
    // let user = getStorageJson('user');
    // let myRoles = ((user.role || {}).data || '').split(',');
    // return (myRoles.includes(role) || myRoles.includes('*'));
};

export const numberFormat = (number, minimumFractionDigits = 0) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(number);
}

export const loading = (content) => {
    return new Promise((resolve) => {
        content = <div dangerouslySetInnerHTML={{ __html: `<i className="fa fa-spin fa-spinner"></i> <span>${content}</span>` }} />
        const loading = Modal.info({
            icon: null,
            title: null,
            centered: true,
            content,
            width: '250px',
            cancelText: <div />,
        });
        resolve(loading);
    });
}

export const alert = (title, content, props, onOK) => {
    Modal.warning({
        icon: null,
        title,
        centered: true,
        content: <span dangerouslySetInnerHTML={{ __html: content }} />,
        okText: 'Okay!',
        width: '300px',
        cancelText: <div />,
        ...props,
        onOk: (close) => {
            close();
            onOK && onOK();
        }
    });
}

export const discount = (amount, disc) => {
    return amount * (disc / 100);
}

export const discounted = (amount, disc) => {
    return amount - discount(amount, disc);
}

export const smsCount = (e) => {
    return new Promise((resolve) => {
        if (e) {
            var part1 = 158;
            var part2 = 145;
            var part3 = 152;
            var pages = 0;
            // var remaining = 0;
            var chars = e.length;
            if (chars <= part1) {
                pages = 1;
                // remaining = part1 - chars;
            } else if (chars <= (part1 + part2)) {
                pages = 2;
                // remaining = (part1 + part2) - chars;
            } else if (chars > (part1 + part2)) {
                var moreM = Math.ceil((chars - part1 - part2) / part3);
                pages = 2 + moreM;
                // remaining = part1 + part2 + (moreM * part3) - chars;
            }
            resolve(`${chars} / ${pages} SMS`);
        } else {
            resolve('0 / 0 SMS');
        }

    });
}

export const datesBetween = (startDate, endDate, format = 'YYYY-MM-DD') => {
    var dates = [];
    var currentDate = startDate;
    var addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    while (currentDate <= endDate) {
        var date = moment(currentDate).format(format);
        if (!dates.includes(date)) {
            dates.push(date);
        }
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};

export const chunk = (inputArray, chunkSize) => {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);
}