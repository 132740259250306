import React from 'react';
import { func } from '../utils';

const Loading = (props) => {

    return (
        <React.Fragment>
            <div className="flex-middle" style={{ height: '70vh', justifyContent: 'center' }}>
                <div className="text-center">
                    {/* <div className="dimmer active">
                    <div className="lds-ring" style={{ margin: '10px auto' }}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    </div> */}
                    {func.fspinner_sm}
                    <div dangerouslySetInnerHTML={{ __html: props.text }} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Loading;