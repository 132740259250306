import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import localeIntl from './assets/intl/data.json';
import * as authAct from './store/auth/_authActions';
import * as utilsAct from './store/utils/_utilsActions';
import { axius, func } from './utils';
import { Loading } from './components';


const Main = React.lazy(() => import('./Main'));
const Authenticate = React.lazy(() => import('./screens/Auth'));

const LoadingHTML = () => (
  <div className="text-center flex-middle" style={{ width: '100%', height: '100vh', justifyContent: 'center' }}>
    <Loading text="starting app..." />
  </div>
);

class App extends React.Component {

  state = {
    loadingApp: false,
  }

  componentDidMount() {
    this.initApp();
  }

  initApp = () => {
    // ::: run some things before loadingApp the MainApp
    if (this.props._auth.authenticated) {
      axius.get(`users/${this.props._auth.logg.uuid}`).then((res) => {
        this.setState({ loadingApp: false });
        if (res.status === 200 && res.data.admin === 1) {
          this.props.signInSuccess(res.data);
        } else {
          if (res.status === 505) {
            this.props.signOutSuccess();
          }
        }
      });
    }

    axius.get('settings').then((res) => {
      if (res.status === 200) {
        this.props.setSetSettings('settings', res.data);
        var oldVersion = func.app.version;
        var newVersion = res.data['app_version'];
        if (newVersion > oldVersion) {
          func.alert('Update', 'There is a new version of the App, we need to update.', {
            okText: 'Sure, Update!',
            maskClosable: false,
          }, () => {
            caches.keys().then(keys => {
              Promise.all(keys.map(key => caches.delete(key))).then(() => {
                window.history.forward(1);
                window.location.forcedReload(true);
              });
            });
          });
        }
      }
    });

    axius.get('settings/countries').then((res) => {
      if (res.status === 200) {
        this.props.setSetSettings('countries', res.data);
      }
    });
  }

  render() {
    const { loadingApp } = this.state;
    const { _auth: { authenticated }, _utils: { lang } } = this.props;

    return (
      <React.Fragment>
        <React.Suspense fallback={<LoadingHTML />}>
          <IntlProvider locale={lang} defaultLocale="en" messages={localeIntl[lang]}>
            <Router>
              {loadingApp ? (<LoadingHTML />) : (authenticated ? (<Route render={() => <Main />} />) : (<Authenticate />))}
            </Router>
          </IntlProvider>
        </React.Suspense>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => ({
  _data: state.data,
  _auth: state.auth,
  _utils: state.utils,
});

const mapDispatchToProps = (dispatch) => ({
  signInSuccess: (data) => {
    dispatch(authAct.signInSuccess(data));
  },
  setSetSettings: (key, value) => {
    dispatch(utilsAct.setSetSettings(key, value));
  },
  signOutSuccess: () => {
    dispatch(authAct.signOutSuccess());
  },
  profileUpdated: (data) => {
    dispatch(authAct.profileUpdated(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);