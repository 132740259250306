import { func } from '../../utils';
import { SIGNIN_SUCCESS, SIGNOUT_SUCCESS, UPDATE_PROFILE } from '../_types';

export function signInSuccess(data) {
    return dispatch => {
        func.setStorageJson('user', data);
        dispatch({
            type: SIGNIN_SUCCESS,
            payload: { data }
        });
    }
};

export function signOutSuccess() {
    return dispatch => {
        func.delStorage('token');
        func.delStorage('user');
        func.redirect('/');
        dispatch({ type: SIGNOUT_SUCCESS });
    }
};



export function profileUpdated(payload) {
    return dispatch => {
        func.setStorageJson('user', payload);
        dispatch({ type: UPDATE_PROFILE, payload });
    }
};